/*
 * @Author: Musa Tabitay
 * @Date: 2021-12-30 23:15:38
 * @LastEditTime: 2022-04-23 22:54:08
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { getCookie } from '@/utils/util'
import { wechatRedirect } from '@/api/user'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // name: 'layout', // 如果父路由有默认子路由，那它的name没有意义
    component: () => import('@/views/layout'),
    children: [
      {
        path: '', // 默认子路由，只能有一个
        name: 'home',
        component: () => import('@/views/home'),
        // 需要登录后才能访问的页面
        meta: { requiresAuth: true }
      },
      {
        path: '/my',
        name: 'my',
        component: () => import('@/views/my'),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/list/:id',
    name: 'list',
    component: () => import('@/views/article/list'),
    // 将路由动态参数映射到组件的 props 中，更推荐这种做法
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/Search'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/grid',
    name: 'grid',
    component: () => import('@/views/Grid'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/category/:id',
    name: 'category',
    component: () => import('@/views/Category'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/topic/:code/:id',
    name: 'topic',
    component: () => import('@/views/Topic'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/topicList',
    name: 'topicList',
    component: () => import('@/views/Topic/List'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/mylist',
    name: 'mylist',
    component: () => import('@/views/my/List'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/my/About'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('@/views/my/Help'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/vip',
    name: 'vip',
    component: () => import('@/views/my/Vip'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/detail/:parent_id/:type',
    name: 'detail',
    component: () => import('@/views/article/Detail'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/teacher/:teacher_id',
    name: 'teacher',
    component: () => import('@/views/Teacher'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import('@/views/Activity/List'),
    meta: { requiresAuth: true }
  },
  {
    path: '/activityDetail/:code/:id',
    name: 'activityDetail',
    component: () => import('@/views/Activity/Detail'),
    meta: { requiresAuth: true }
  }
]

const router = new VueRouter({
  routes
})

// 前置导航守卫
router.beforeEach((to, from, next) => {
  const openId = getCookie('openId')
  // 检查用户是否授权过
  if (to.meta.requiresAuth && !openId) {
    location.href = `${wechatRedirect}?url=${encodeURIComponent(location.href)}&scope=snsapi_userinfo`
  }

  next()
})

export default router
